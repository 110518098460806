<template>
  <div>
    <vx-card actionable class="cardx" title="Acads Admin Dashboard">
      <vs-row style="margin-bottom: 3%">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="3"
        >
          <v-select
            searchable
            clearable
            class="w-full"
            label="text"
            placeholder="Duration"
            :options="duration"
            v-model="durationVmodel"
            style="z-index:3000"
          ></v-select>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="3"
        >
          <!-- <VueHotelDatepicker @confirm="getValue" /> -->
          <date-range-picker
            ref="picker"
            :opens="'center'"
            :locale-data="{ firstDay: 1, format: 'DD-MMM-YYYY' }"
            :minDate="null"
            :maxDate="null"
            :singleDatePicker="false"
            :timePicker="false"
            style="width: 240px"
            :timePicker24Hour="true"
            :showWeekNumbers="false"
            :showDropdowns="false"
            :autoApply="false"
            v-model="dateRange"
            @update="clear"
          >
            <!-- <div
            slot="input"
            slot-scope="picker"
            style="min-width: 350px;"
          >{{ picker.startDate }} - {{ picker.endDate }}</div>-->
          </date-range-picker>
        </vs-col>
      </vs-row>
      <div slot="header">All Over India</div>
      <vs-table :data="countryData">
        <template slot="thead">
          <vs-th>Acads Name</vs-th>
          <vs-th>City</vs-th>
          <vs-th>Escalations (Today)</vs-th>
          <vs-th>Second Visits (Today)</vs-th>
          <vs-th>Escalations</vs-th>
          <vs-th>Second Visits</vs-th>
          <vs-th>Calls</vs-th>
          <vs-th>B2C</vs-th>
          <vs-th>Untracked</vs-th>
          <vs-th>Missed Calls</vs-th>
          <vs-th>MWB Calls</vs-th>
          <vs-th>EWB Calls</vs-th>
        </template>
        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>{{ tr.name }} </vs-td>
            <vs-td>{{ tr.city }} </vs-td>
            <vs-td>{{ tr.escalations_today }}</vs-td>
            <vs-td>{{ tr.second_visit_today }}</vs-td>
            <vs-td>{{ tr.escalations }}</vs-td>
            <vs-td>{{ tr.second_visits }}</vs-td>
            <vs-td>{{ getTotalCallCount(tr.all_call_logs) }}</vs-td>
            <vs-td>{{ getCenrtainTypeCalls(tr.all_call_logs, "B2C") }}</vs-td>
            <vs-td>{{ 0 }}</vs-td>
            <vs-td>{{ getMissedCalls(tr.all_call_logs) }}</vs-td>
            <vs-td>{{ getMWBCalls(tr.all_call_logs) }}</vs-td>
            <vs-td>{{ getEWBCalls(tr.all_call_logs) }}</vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vx-card>
  </div>
</template>

<script>
// import router from "@/router";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import vSelect from "vue-select";
import datatable from "../components/pagesComponents/adminDashboardUtilizationTable.vue";
import axios from "axios";
import constants from "../../constants.json";
import moment from "moment";
export default {
  mounted() {
    if (localStorage.getItem("role") !== "ac2") {
      this.decideRedirect();
    } else {
      // let navbar = document.getElementsByClassName("vx-navbar-wrapper");
      // navbar[0].style.display = "none";
      this.loadopen = true;
      // this.setDefault();
      this.fetchData();
    }
  },
  watch: {
    dateRange() {
      this.fetchData();
    },
    durationVmodel(value) {
      if (value !== null) {
        this.setDateRange(value.text);
      }
    }
  },
  data() {
    return {
      open: false,
      name: "App",
      type: "margin",
      dateRange: {
        // startDate: this.getMonday(new Date()),
        startDate: new Date(),
        endDate: new Date()
      },
      durationVmodel: "WTD",
      duration: [
        { text: "WTD", value: 0 },
        { text: "MTD", value: 1 },
        { text: "QTD", value: 2 },
        { text: "YTD", value: 3 }
      ],
      cities: [],
      countryData: [],
      users: [],
      loadopen: true
    };
  },
  methods: {
    getTotalCallCount(call_logs) {
      return call_logs.length;
    },
    getCenrtainTypeCalls(call_logs, type) {
      let arr = [];
      call_logs.forEach(call => {
        if (call.contact_type === type) {
          arr.push(call);
        }
      });
      return arr.length;
    },
    getMissedCalls(call_logs) {
      let arr = [];
      call_logs.forEach(call => {
        if (call.directory === "MISSED") {
          arr.push(call);
        }
      });
      return arr.length;
    },
    getMWBCalls(call_logs) {
      let arr = [];
      call_logs.forEach(call => {
        if (call.contact_type === "B2C" && call.level !== "M7") {
          arr.push(call);
        }
      });
      return arr.length;
    },
    getEWBCalls(call_logs) {
      let arr = [];
      call_logs.forEach(call => {
        if (call.contact_type === "B2C" && call.level === "M7") {
          arr.push(call);
        }
      });
      return arr.length;
    },
    clear() {
      this.durationVmodel = null;
    },
    spoc_name() {
      this.$router.push("SingleBdeStats");
    },
    setDefault() {
      this.durationVmodel = "WTD";
    },
    setDateRange(category) {
      var d = new Date();
      var startdate = "";
      var enddate = new Date();
      switch (category) {
        case "WTD": {
          startdate = this.getMonday(new Date());
          break;
        }
        case "MTD": {
          startdate = d.setDate(1);
          break;
        }
        case "QTD": {
          let month = d.getMonth();
          if (month === 0 || month === 1 || month === 2) {
            startdate = d.setMonth(0);
            startdate = d.setDate(1);
          } else if (month === 3 || month === 4 || month === 5) {
            startdate = d.setMonth(3);
            startdate = d.setDate(1);
          } else if (month === 6 || month === 7 || month === 8) {
            startdate = d.setMonth(6);
            startdate = d.setDate(1);
          } else if (month === 9 || month === 10 || month === 11) {
            startdate = d.setMonth(9);
            startdate = d.setDate(1);
          }
          break;
        }
        case "YTD": {
          startdate = d.setMonth(0);
          startdate = d.setDate(1);
          break;
        }
      }
      this.dateRange = {
        startDate: startdate,
        endDate: enddate
      };
      this.fetchData();
    },
    getMonday(d) {
      d = new Date(d);
      var day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
      return new Date(d.setDate(diff));
    },
    fetchData() {
      // this.open = false;
      this.$vs.loading();
      let fromdate = this.datatoTimestamp(this.dateRange.startDate);
      let todate = this.datatoTimestamp(this.dateRange.endDate);
      let obj = {
        from_date: fromdate,
        to_date: todate
      };
      let url = `${constants.SERVER_API}getAcadMemberStats`;
      axios
        .get(url, {
          params: obj,
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
        })
        .then(response => {
          if (response.data.status === "error") {
            this.$vs.notify({
              title: "Error",
              text: response.data.message,
              color: "danger"
            });
          } else {
            this.countryData = response.data.data;
            // this.executeDataExtract(response.data.spocs);
            // console.log("acads", response);
          }
          this.$vs.loading.close();
        })
        .catch(error => {
          this.$vs.loading.close();
          this.handleError(error);
        });
    },
    closeAll() {
      // console.log("running");
      this.cities.forEach(city => {
        city.open = false;
        city.data = [];
      });
    },
    onCollapseClick(providedCityName) {
      // console.log(`Provided City is ${providedCityName}`);
      this.cities.forEach(city => {
        if (city.cityName === providedCityName) {
          city.data = [];
          city.open = !city.open;
        } else {
          city.open = false;
          city.data = [];
        }
      });
      // console.log(this.cities);
      this.fetchDataCityBased(providedCityName);
    },
    executeDataExtract(rawData) {
      // console.log(rawData);
      this.countryData = [];
      //   this.extractUtilization(rawData);
      rawData.forEach(datapoint => {
        this.countryData.push(datapoint);
      });
    },
    /* extractUtilization(rawData) {
      let finalTotal = {
        attendance: [],
        call_logs: [],
        city: "",
        coverage: rawData.reduce((r, a) => r + a.coverage, 0),
        delays: rawData.reduce((r, a) => r + a.delays, 0),
        dues: "",
        enrollment: rawData.reduce((r, a) => r + a.enrollment, 0),
        mwb_count: rawData.reduce((r, a) => r + a.mwb_count, 0),
        overall: 0,
        spoc_id: 0,
        spoc_name: "TOTAL",
        sr: "",
        utilization: 0,
        visitors: []
      };
      rawData.forEach(spoc => {
        // console.log(spoc);
        if (
          this.datatoTimestamp(this.dateRange.startDate) ===
          this.datatoTimestamp(this.dateRange.endDate)
        ) {
          spoc.utilization = Math.round(
            this.getvisitPercentage(spoc.visitors) +
              this.calculateUtilization(spoc.call_logs)
          );
        } else {
          spoc.utilization = Math.round(
            this.calculateVisitAverage(
              spoc.visitors,
              spoc.attendance,
              spoc.spoc_name
            ) + this.calculateAverageUtlization(spoc.call_logs, spoc.attendance)
          );
        }
        finalTotal.utilization = rawData.reduce((r, a) => r + a.utilization, 0);
        spoc.visitors.forEach(visitor => {
          finalTotal.visitors.push(visitor);
        });
      });
      rawData.push(finalTotal);
      // console.log(finalTotal);
    }, */
    calculateVisitAverage(visitors, attendance, spoc_name) {
      let arr = this.getVisitpercentageperday(visitors);
      let average = this.calculateAverage(arr, attendance, spoc_name);
      if (isNaN(average)) {
        return 0;
      } else {
        return average;
      }
    },
    getVisitpercentageperday(engagements) {
      let time = [];
      let ByDayData = [];
      engagements.forEach(piece => {
        piece.date_for_calc = piece.created_at.split(" ")[0];
      });
      var unique = engagements
        .map(name => {
          return {
            count: 1,
            name: name.date_for_calc
          };
        })
        .reduce((a, b) => {
          a[b.name] = (a[b.name] || 0) + b.count;
          return a;
        }, {});
      for (var key in unique) {
        if (unique.hasOwnProperty(key)) {
          time.push(key);
        }
      }
      time.forEach(time => {
        let obj = {
          time: time,
          data: []
        };
        engagements.forEach(log => {
          if (log.date_for_calc === time) {
            obj.data.push(log);
          }
        });
        ByDayData.push(obj);
      });
      // console.log(unique);
      // console.log(ByDayData);
      let percentage = [];
      ByDayData.forEach(dayData => {
        let value = this.getvisitPercentage(dayData.data);
        percentage.push(value);
      });
      // console.log(percentage);
      return percentage;
    },
    getvisitPercentage(visitors) {
      let percentage = 0;
      visitors.forEach(day => {
        if (day.visit_status === "First") {
          percentage = percentage + 12.5;
        } else if (day.visit_status === "Enrollment") {
          percentage = percentage + 6.25;
        } else if (day.visit_status === "Repeat") {
          percentage = percentage + 3.125;
        }
      });
      return percentage;
    },
    calculateAverage(arrpercentage, attendance) {
      let add = 0;
      let present = attendance.filter(function(att) {
        return att.type === "present";
      });
      arrpercentage.forEach(arr => {
        add = add + arr;
      });
      // console.log(add, present.length, spoc_name);
      let ave = Math.round(add / present.length);
      if (!isFinite(ave)) {
        return 0;
      } else {
        return ave;
      }
    },
    calculateAverageUtlization(calllogs, attendance) {
      let percentageArr = this.getPercentageByDay(calllogs);
      let average = this.calculateAverage(percentageArr, attendance);
      if (isNaN(average)) {
        return 0;
      } else {
        return average;
      }
    },
    getPercentageByDay(callLogs) {
      let time = [];
      let ByDayData = [];
      callLogs.forEach(log => {
        log.updated_at_date = moment.unix(log.time / 1000).format("DD-MM-YYYY");
      });
      var unique = callLogs
        .map(name => {
          return {
            count: 1,
            name: name.updated_at_date
          };
        })
        .reduce((a, b) => {
          a[b.name] = (a[b.name] || 0) + b.count;
          return a;
        }, {});
      for (var key in unique) {
        if (unique.hasOwnProperty(key)) {
          time.push(key);
        }
      }
      time.forEach(time => {
        let obj = {
          time: time,
          data: []
        };
        callLogs.forEach(log => {
          if (log.updated_at_date === time) {
            obj.data.push(log);
          }
        });
        ByDayData.push(obj);
      });
      let percentage = [];
      ByDayData.forEach(dayData => {
        let value = this.calculateUtilization(dayData.data);
        percentage.push(value);
      });
      return percentage;
    },
    calculateUtilization(call_logs) {
      let secondsSpoke = 0;
      call_logs.forEach(call => {
        if (
          call.contact_type === "B2C" ||
          call.contact_type === "B2BIR" ||
          call.contact_type === "B2BCR"
        ) {
          secondsSpoke = secondsSpoke + call.call_duration;
        }
      });
      let minutesSpoke = this.getMinutes(secondsSpoke);
      return Math.round((minutesSpoke * 100) / 180);
    },
    getMinutes(seconds) {
      return Math.round(seconds / 60);
    },
    extractCities(rawData) {
      this.cities = [];
      let newobj = [];
      var unique = rawData
        .map(raw => {
          return {
            count: 1,
            city: raw.city
          };
        })
        .reduce((a, b) => {
          a[b.city] = (a[b.city] || 0) + b.count;
          return a;
        }, {});
      for (var key in unique) {
        if (key !== "") {
          if (unique.hasOwnProperty(key)) {
            let finalobj = {};
            finalobj.cityName = key;
            let objarr = [];
            // rawData.forEach(raw => {
            //   if (raw.city === key) {
            //     objarr.push(raw);
            //   }
            // });
            finalobj.data = objarr;
            finalobj.open = false;
            newobj.push(finalobj);
          }
        }
      }
      this.cities = this.sortNestedArrayAlphabeticallyCustom(newobj);
      // console.log(this.cities);
      this.open = true;
      this.$vs.loading.close();
      this.$nextTick(() => {
        this.openFirstCollapse();
      });
      // this.openFirstCollapse();
    },
    sortNestedArrayAlphabeticallyCustom(ObjectsArray) {
      // console.log("going to sort the data");
      // console.log(ObjectsArray);
      let sortedArray = ObjectsArray.sort(function(a, b) {
        if (a.cityName < b.cityName) {
          return -1;
        }
        if (a.cityName > b.cityName) {
          return 1;
        }
        return 0;
      });
      // console.log(sortedArray);
      return sortedArray;
    },
    openFirstCollapse() {
      let countrycollapse = document.getElementById("allindiacollapse");
      countrycollapse.classList.add("open-item");
      countrycollapse.children[1].style.maxHeight = "100%";
    },
    datatoTimestamp(stringyDate) {
      var dateyDate = new Date(this.timetoTimestamp(stringyDate));
      var ms = dateyDate.valueOf();
      return ms;
    },
    timetoTimestamp(incoming) {
      return new Date(incoming).setHours(0, 0, 0, 0) / 1000;
    }
  },
  components: {
    // VueHotelDatepicker,
    DateRangePicker,
    "v-select": vSelect,
    datatable
  },
  beforeDestroy() {
    let navbar = document.getElementsByClassName("vx-navbar-wrapper");
    navbar[0].style.display = "";
  }
};
</script>

<style>
.vhd-input[data-v-25b15922] {
  min-width: 250px;
  padding: 5px;
  border: 1px solid #eee;
  color: #505050;
  font-size: 16px;
  line-height: 9px;
  border-radius: 8px;
  border-color: lightgray;
  outline: none;
}
.vs--searchable .vs__dropdown-toggle {
  height: 35px;
}
</style>
